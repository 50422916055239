import React, { useEffect } from 'react';

import * as S from './styles';

const GiftCards = () => {
  // Drop-in script from giftup
  useEffect(() => {
    /* eslint-disable prefer-rest-params */

    const conversionCallback = (purchase:any): void => {
      window.dataLayer.push({
        event: 'gift-card-purchase',
        conversion: {
          orderId: purchase.orderId,
          orderTotal: purchase.revenue
        }
      });
    };

    // @ts-ignore
    const dropInGiftup = function() { (function (g, i, f, t, u, p, s) { g[u] = g[u] || function() { (g[u].q = g[u].q || []).push(arguments) }; p = i.createElement(f); p.async = 1; p.src = t; s = i.getElementsByTagName(f)[0]; s.parentNode.insertBefore(p, s); })(window, document, "script", "https://cdn.giftup.app/dist/gift-up.js", "giftup");
      // @ts-ignore
      giftup("conversion", function(payload) { conversionCallback(payload); });
    };
    if(document.readyState === 'complete') {
      dropInGiftup();
    } else {
      // @ts-ignore
      window.addEventListener ? window.addEventListener("load", dropInGiftup, false) : window.attachEvent && window.attachEvent("onload", dropInGiftup);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.BlurbWrapper>
        <S.Blurb>
          Everlywell gift cards make it easy to bring the ones you love one step closer to health with a gift that lets them choose an at-home lab test of their choice. Every lab test offers simple collection, free shipping both ways, and physician-reviewed results and insights to inspire them to take action.
        </S.Blurb>
        <S.Blurb>
          Recipients simply redeem their digital gift card by applying their unique gift card code upon checkout at everlywell.com.
        </S.Blurb>
      </S.BlurbWrapper>
      <div data-site-id="670a9b8c-e6ec-41b0-8f8c-4f91cbf04a09" data-platform="Other" className="gift-up-target">
        <S.Loading>
          Loading Gift Card Form...
        </S.Loading>
      </div>
    </S.Wrapper>
  );
};

export default GiftCards;
