import GiftCards from 'components/GiftCards/index';
import React, { useEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { useProductVariants, useProductBySlug } from 'utils/hooks';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';

const GiftCardsTemplate = () => {
  const productVariants = useProductVariants();
  const productsBySlug = useProductBySlug(productVariants);
  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'Gift Cards',
      },
    });
  });

  return (
    <div data-testid="gift-cards-page">
      <SEO title="Gift Cards" />
      <Layout productsBySlug={productsBySlug}>
        <GiftCards />
      </Layout>
    </div>
  );
};

export default GiftCardsTemplate;
