import styled from 'styled-components';

const BlurbWrapper = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #292b2c;
  margin: 0 auto;
  max-width: 600px;
`;

const Wrapper = styled.div`
  margin: 0 1.5rem;
`;

const Blurb = styled.p`
  margin-top: 1.5rem;
  &:last-child {
    margin-bottom: 1.5rem;
  }
`;

const Loading = styled.p`
  text-align: center;
  margin: 1.5rem;
`;

export { Wrapper, BlurbWrapper, Blurb, Loading };
